<template>
  <v-main class="bg-grey core-view">
    <div
      v-if="
        (!$store.getters.isPreviewing && $store.getters.isUserAdmin) ||
        $route.name == 'consolidatedFiles'
      "
      class="colored-bg"
    />
    <v-container v-scroll.html="onScroll" id="core-view" fluid>
      <v-row dense class="px-0">
        <v-col class="px-3">
          <!-- <v-slide-y-transition mode="out-in"> -->
          <router-view />

          <!-- </v-slide-y-transition> -->
          <v-slide-y-transition mode="in-out">
            <v-btn
              style="z-index: 9999"
              label="Revenir en haut"
              v-show="offsetTop > 120"
              class="mx-2 d-print-none"
              @click="scrollToTop"
              fixed
              right
              bottom
              fab
              dark
              color="primary"
            >
              <v-icon dark> mdi-chevron-up </v-icon>
            </v-btn>
          </v-slide-y-transition>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<style>
@media print {
  div.divider-title {
    padding-top: 0px !important;
    page-break-before: always !important;
    page-break-after: avoid !important;
  }
  .counters-row > div {
    display: inline-block !important;
  }
  .hmd-visualization {
    width: 100% !important;
    display: block !important;
    page-break-inside: avoid !important;
    page-break-before: auto !important;
    page-break-after: auto !important;
  }
  div.row {
    width: 100% !important;
    display: block !important;
    padding-top: 0px !important;
    /*position: relative !important;*/
  }
  /*.divider-title {*/
  /*  display:block !important;*/
  /*  position: relative !important;*/
  /*  page-break-before: auto !important;*/
  /*}*/
  div.hmd-visualization.hmd-visualization-card {
    display: block;
    page-break-inside: avoid !important;
  }
  .hmd-visualization-body .el-table {
    height: auto !important;
  }
  .hmd-visualization-body .el-table,
  .hmd-visualization-body .el-table .el-table__header-wrapper table,
  .hmd-visualization-body .el-table .el-table__header-wrapper,
  .hmd-visualization-body .el-table table,
  .hmd-visualization-body .el-table table thead,
  .hmd-visualization-body .el-table table tbody {
    width: 100% !important;
  }

  .v-main {
    display: block;
    page-break-inside: avoid !important;
  }
  .core-view {
    page-break-inside: avoid !important;
    padding-top: 0 !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
    padding-bottom: 0 !important;
  }
}
.dash {
  transition: 0.3s padding;
}
.v-sheet.v-card,
.v-data-table,
button.v-btn:not(.v-btn--round),
a.v-btn:not(.v-btn--round) {
  border-radius: 8px !important;
}
.v-expansion-panel {
  border-width: none !important;
}
.v-sheet.v-card:not(.v-sheet--outlined),
button.v-btn:not(.v-btn--text),
a.v-btn:not(.v-btn--text) {
  box-shadow: 0 0 2rem 0 rgba(136, 152, 170, 0.15) !important;
}
.bg-grey {
  background-color: #f8f9fe;
}
#core-view {
  position: relative;
  z-index: 2;
}
.colored-bg {
  background: #005fff;
  background: -moz-linear-gradient(
    90deg,
    rgb(42 100 255) 0%,
    rgb(45 98 237) 100%
  );
  background: -webkit-linear-gradient(
    90deg,
    rgb(42 100 255) 0%,
    rgb(45 98 237) 100%
  );
  background: linear-gradient(90deg, rgb(42 100 255) 0%, rgb(45 98 237) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#38a0de",endColorstr="#0076bc",GradientType=1);
  width: 100%;
  height: 320px;
  position: absolute;
  top: -88px;
  z-index: 1;
}
.divider-title {
  font-size: 25px;
  padding: 15px 10px;
}
</style>
<script>
export default {
  name: 'CoreView',
  components: {},
  props: {
    marging: String,
  },
  data() {
    return {
      offsetTop: 0,
    }
  },
  methods: {
    onScroll(e) {
      // console.log(e);
      this.offsetTop = e.target.documentElement.scrollTop
    },
    scrollToTop() {
      this.$vuetify.goTo(0, {})
    },
  },
  metaInfo() {
    return {
      title: process.env.VUE_APP_TITLE,
      /* title: 'Fast Data' */
    }
  },
}
</script>
